import { 
  RepeatMedsClaimCondition,
} from "../_repeat-medication/interfaces/repeat-medication-claim-condition.interface";

export const claimDetailStateName = 'claimDetail';

export interface ClaimDetailState {
  conditions: RepeatMedsClaimCondition[];
  loading: boolean;
  error: unknown;
}

export const initialClaimDetailState: ClaimDetailState= {
  conditions: [],
  loading: false,
  error: null,
};