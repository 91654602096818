import {
  RepeatMedsClaimCondition,
} from "../_repeat-medication/interfaces/repeat-medication-claim-condition.interface";

export const repeatMedicationStateName = 'repeatMedication';

export interface RepeatMedicationState {
  condition: RepeatMedsClaimCondition,
  treatmentFrom: Date,
  treatmentTo: Date,
  medications: string[],
  amount: number,
  hasPrescription: boolean | null;
  prescriptionFiles: File[];
  invoiceFiles: File[];
}

export const initialRepeatMedicationState: RepeatMedicationState = {
  condition: null,
  treatmentFrom: null,
  treatmentTo: null,
  amount: null,
  medications: null,
  hasPrescription: null,
  prescriptionFiles: [],
  invoiceFiles: [],
};