import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { PolicyService } from "../services/policy.service";
import { policyActions } from "./policy.actions";
import { catchError, filter, map, switchMap, tap } from "rxjs/operators";
import { PolicyPaymentFrequency } from "../enums/policy-payment-frequency.enum";
import { Store } from "@ngrx/store";
import { PolicySelectors } from "./policy.selectors";
import { selectIsFeatureEnabled } from "@shared/feature-flags/store/feature-flag.selector";
import { FeatureFlags } from "@shared/enums/feature-flags.enum";
import { StaticDocumentService } from "../services/static-document.service";
import { ClaimService } from "@features/claims/services/claim.service";
import { PolicyMethods } from "../services/policy-methods.service";
import {
  SupportingDocumentsService,
} from "@features/supporting-documents/services/supporting-documents.service";
import { of } from "rxjs";
import { CancellationService } from "../services/cancellation.service";
import { Policy } from "@interfaces/policy";
import { PolicyOtherDocumentsService } from "../services/policy-other-documents-service";
import { RenewalActions } from "@features/change-cover/store/actions/renewal.actions";
import { PolicyDirectDebitService } from "../services/policy-direct-debit.service";
import {
  PolicyPremiumRecalculationService,
} from "../services/policy-premium-recalculation.service";
import { ToastService } from "@shared/ui/components/toast/toast.service";
import { PaymentFailureComponent } from "@shared/ui/components/payment-failure/payment-failure.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class PolicyEffects {
  fetchPolicy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyActions.fetchPolicy),
      filter(({ policyNoLong }) => policyNoLong !== undefined),
      switchMap(({ policyNoLong }) =>
        this.policyService.getPolicy(policyNoLong).pipe(
          map((policy) => policyActions.fetchPolicySuccess({ policy })),
        ),
      ),
    );
  });

  fetchPreviousPolicy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        policyActions.fetchPreviousPolicy,
        RenewalActions.startRenewalJourney,
      ),
      concatLatestFrom(() =>
        this.store.select(PolicySelectors.selectPolicy),
      ),
      switchMap(([_, policy]) =>
        this.policyService.getPolicy(policy?.previousPolicyRef).pipe(
          map((previousPolicy: Policy) =>
            policyActions.fetchPreviousPolicySuccess({
              previousPolicy,
            }),
          ),
        ),
      ),
    );
  });

  isInRenewalWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyActions.fetchPolicySuccess),
      map(({ policy }) =>
        policyActions.setIsInRenewalWindow({
          isInRenewalWindow: !this.policyMethods.isMidTerm(policy),
        }),
      ),
    );
  });

  policySuccessFetchHorse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyActions.fetchPolicySuccess),
      concatLatestFrom(() =>
        this.store.select(selectIsFeatureEnabled(FeatureFlags.EquineTimeLimited)),
      ),
      filter(([_, isEquineTimeLimitedEnabled]) => isEquineTimeLimitedEnabled),
      filter(([props, _]) => this.policyMethods.isHorse(props.policy)),
      map(() => policyActions.fetchHorse()),
    );
  });

  fetchHorse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyActions.fetchHorse),
      concatLatestFrom(() => this.store.select(PolicySelectors.selectPolicy)),
      filter(([_, policy]) => this.policyMethods.isHorse(policy)),
      switchMap(([_, policy]) =>
        this.policyService.getHorse(policy.policyUniqueRef).pipe(
          map((horse) => policyActions.fetchHorseSuccess({
            horse,
          })),
        ),
      ),
    );
  });

  fetchDirectDebitDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        policyActions.fetchDirectDebitDetails,
        policyActions.paymentPageOpened,
      ),
      concatLatestFrom(() => this.store.select(PolicySelectors.selectPolicy)),
      filter(([_, policy]) => policy.paymentFrequency === PolicyPaymentFrequency.Monthly),
      switchMap(([_, policy]) =>
        this.policyService.getDirectDebitDetails(policy.policyUniqueRef).pipe(
          map((directDebitDetails) => policyActions.fetchDirectDebitDetailsSuccess({
            directDebitDetails,
          })),
        ),
      ),
    );
  });

  fetchCanEditDirectDebitDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        policyActions.fetchCanEditDirectDebitDetails,
        policyActions.paymentPageOpened,
      ),
      concatLatestFrom(() => this.store.select(PolicySelectors.selectPolicy)),
      filter(([_, policy]) => policy.paymentFrequency === PolicyPaymentFrequency.Monthly),
      switchMap(([_, policy]) =>
        this.policyService.canEditDirectDebitDetails(policy.policyUniqueRef).pipe(
          map((canEditDirectDebitDetails) => policyActions.fetchCanEditDirectDebitDetailsSuccess({
            canEditDirectDebitDetails,
          })),
        ),
      ),
    );
  });

  fetchShouldChangeDirectDebitDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        policyActions.fetchShouldChangeDirectDebitDetails,
        policyActions.paymentPageOpened,
      ),
      concatLatestFrom(() => this.store.select(PolicySelectors.selectPolicy)),
      filter(([_, policy]) => policy.paymentFrequency === PolicyPaymentFrequency.Monthly),
      switchMap(([_, policy]) =>
        this.policyService.shouldChangeDirectDebitDetails(policy.policyUniqueRef).pipe(
          map((shouldChangeDirectDebitDetails) => 
            policyActions.fetchShouldChangeDirectDebitDetailsSuccess({
              shouldChangeDirectDebitDetails,
            })),
        ),
      ),
    );
  });

  fetchExcess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        policyActions.fetchExcess,
        policyActions.fetchPolicySuccess,
      ),
      concatLatestFrom(() => this.store.select(PolicySelectors.selectPolicy)),
      switchMap(([_, policy]) =>
        this.policyService.getExcess(policy.policyUniqueRef).pipe(
          map((excess) => policyActions.fetchExcessSuccess({ excess })),
        ),
      ),
    );
  });

  fetchUpcomingPayments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        policyActions.fetchUpcomingPayments,
        policyActions.paymentPageOpened,
      ),
      concatLatestFrom(() => this.store.select(PolicySelectors.selectPolicy)),
      switchMap(([_, policy]) =>
        this.policyService.upcomingPayment(policy.policyUniqueRef).pipe(
          map((upcomingPayments) => policyActions.fetchUpcomingPaymentsSuccess({
            upcomingPayments,
          })),
        ),
      ),
    );
  });

  fetchCanChangeCover$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        policyActions.fetchPolicySuccess,
        policyActions.updateExcessSuccess,
      ),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectPolicy),
        this.store.select(selectIsFeatureEnabled(FeatureFlags.ChangeOfCover)),
      ]),
      filter(([_, _policy, changeOfCoverEnabled]) => changeOfCoverEnabled),
      switchMap(([_, policy]) =>
        this.policyService.canChangeCover(policy.policyUniqueRef).pipe(
          map((canChangeCover) => policyActions.fetchCanChangeCoverSuccess({ canChangeCover })),
        ),
      ),
    );
  });

  fetchStaticDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyActions.fetchPolicySuccess),
      concatLatestFrom(() => this.store.select(PolicySelectors.selectPolicy)),
      switchMap(([_, policy]) =>
        this.staticDocumentService.getAllStaticDocuments(policy.policyUniqueRef).pipe(
          map((staticDocuments) => policyActions.fetchStaticDocumentsSuccess({ staticDocuments })),
        ),
      ),
    );
  });

  fetchExclusions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyActions.fetchPolicySuccess),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectPolicy),
      ]),
      switchMap(([_, policy]) =>
        this.policyService
          .getExclusions({
            top: 1000,
            skip: 0,
            policyUniqueRef: policy.policyUniqueRef,
          })
          .pipe(
            map((exclusions) =>
              policyActions.fetchExclusionsSuccess({ exclusions: exclusions.results }),
            ),
          ),
      ),
    );
  });

  fetchEditableFields$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyActions.fetchPolicySuccess),
      concatLatestFrom(() => this.store.select(PolicySelectors.selectPolicy)),
      switchMap(([_, policy]) =>
        this.policyService.getEditableFields(policy.policyUniqueRef).pipe(
          map((editableFields) => policyActions.fetchEditableFieldsSuccess({ editableFields })),
        ),
      ),
    );
  });

  fetchClaims$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyActions.fetchPolicySuccess),
      concatLatestFrom(() => this.store.select(PolicySelectors.selectPolicy)),
      switchMap(([_, policy]) =>
        this.claimService.getClaims({
          top: 100,
          skip: 0,
          policyUniqueRef: policy.policyUniqueRef,
        }).pipe(
          map((claims) => policyActions.fetchClaimsSuccess({ claims: claims.results })),
        ),
      ),
    );
  });

  fetchCoverItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        policyActions.fetchExcessSuccess,
        policyActions.fetchCoverItems,
      ),
      concatLatestFrom(() => this.store.select(PolicySelectors.selectPolicy)),
      filter(([_, policy]) => policy !== null),
      switchMap(([_, policy]) =>
        this.policyService.getCoverSummary(policy.policyUniqueRef).pipe(
          map((coverItems) => policyActions.fetchCoverItemsSuccess({ coverItems })),
        ),
      ),
    );
  });

  excessEditState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyActions.fetchExcessSuccess),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectExcess),
        this.store.select(PolicySelectors.selectPolicy),
      ]),
      map(([_, excess, policy]) =>
        policyActions.excessEditStateChanged({
          editState: this.policyMethods.getExcessEditState(
            this.policyMethods.canReviewExcess(excess),
            excess.anyNonCancelledDebitOrders,
            excess.isInRenewalWindow,
            policy,
          ),
        }),
      ),
    );
  });

  fetchUploadedDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        policyActions.fetchPolicySuccess,
        policyActions.uploadedDocumentsPageChanged,
      ),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectPolicy),
        this.store.select(PolicySelectors.selectUploadedDocumentsPageParams),
      ]),
      switchMap(([_, policy, params]) => 
        this.uploadedDocumentService.getUploadedDocumentsForPolicy({
          top: params.top,
          skip: params.skip,
          policyUniqueRef: policy.policyUniqueRef,
        }).pipe(
          map((response) => policyActions.fetchUploadedDocumentsSuccess({
            uploadedDocuments: response.results,
          })),
        ),
      ),
    );
  });

  updateHorseColour$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyActions.updateHorseColour),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectPolicy),
      ]),
      switchMap(([props, policy]) =>
        this.policyService.updateHorseColour(policy.policyUniqueRef, props.colour).pipe(
          map((response) => policyActions.updateHorseColourSuccess({colour: response})),
          catchError(() => of(policyActions.updateHorseColourFailure())),
        ),
      ),
    );
  });

  fetchCanChangePaymentDay$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        policyActions.fetchCanChangePaymentDay,
        policyActions.paymentPageOpened,
      ),
      concatLatestFrom(() => this.store.select(PolicySelectors.selectPolicy)),
      filter(([_, policy]) => policy !== null),
      switchMap(([_, policy]) =>
        this.policyDirectDebitService.getCanChangePaymentDay(policy.policyUniqueRef).pipe(
          map((canChangePaymentDay) => policyActions.fetchCanChangePaymentDaySuccess(
            { canChangePaymentDay },
          )),
        ),
      ),
    );
  });

  updatePaymentDayFromSelectedPaymentDay$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyActions.updatePaymentDayFromSelectedPaymentDay),
      concatLatestFrom(() => 
        this.store.select(PolicySelectors.selectNewPreferredPaymentDay),
      ),
      map(([_, newPreferredPaymentDay]) =>
        policyActions.updatePaymentDay({ paymentDay: newPreferredPaymentDay }),
      ),
    );
  });

  updatePassportNo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyActions.updatePassport),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectPolicy),
      ]),
      switchMap(([props, policy]) =>
        this.policyService.updatePassportNo(
          policy.policyUniqueRef,
          { passportNo: props.passportNo },
        ).pipe(
          map((response) => policyActions.updatePassportSuccess({
            passportNo: response.passportNo,
          })),
          catchError(() => of(policyActions.updatePassportFailure())),
        ),
      ),
    );
  });

  fetchCanChangePassportNo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyActions.fetchCanChangePassportNo),
      concatLatestFrom(() => this.store.select(PolicySelectors.selectPolicy)),
      switchMap(([_, policy]) =>
        this.policyService.getCanChangePassportNo(policy.policyUniqueRef).pipe(
          map((response) => policyActions.fetchCanChangePassportNoSuccess({
            canChangePassportNo: response.canChangePassportNo,
          })),
        ),
      ),
    );
  });

  updatePaymentDay$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyActions.updatePaymentDay),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectPolicy),
      ]),
      switchMap(([props, policy]) =>
        this.policyDirectDebitService.updatePaymentDay(
          policy.policyUniqueRef,
          props.paymentDay)
          .pipe(
            map((result) => {
              if(result.createdId > 0){
                return policyActions.updatePaymentDaySuccess({paymentDay: props.paymentDay});
              }
                
              return policyActions.updatePaymentDayFailure();
            }),
            catchError(() => of(policyActions.updatePaymentDayFailure())),
          ),
      ),
    );
  });

  updatePaymentDaySuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyActions.updatePaymentDaySuccess),
      tap(() => {
        this.toastService.toasts = [];
        this.toastService.show(
          'Changes have been successful, this may take a few minutes to show.',
          { classname: 'text-dark' });
      }),
    );
  },
  { dispatch: false });

  recalculateSchedulePaymentDay$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyActions.recalculateSchedulePaymentDay),
      concatLatestFrom(() =>
        this.store.select(PolicySelectors.selectPolicy),
      ),
      switchMap(([paymentDaySuccessProps, policy]) =>
        this.policyPremiumRecalculationService.getPolicyScheduleRecalculation(
          policy.policyUniqueRef, 
          { paymentDay: paymentDaySuccessProps.paymentDay },
        ).pipe(
          map((scheduleRecalculation) => policyActions.recalculateSchedulePaymentDaySuccess({ 
            scheduleRecalculation,
            paymentDay: paymentDaySuccessProps.paymentDay,
          })),
        ),
      ),
    );
  });

  recalculateScheduleEarlyPayment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyActions.recalculateScheduleEarlyPayment),
      concatLatestFrom(() =>
        this.store.select(PolicySelectors.selectPolicy),
      ),
      switchMap(([recalculateScheduleProps, policy]) =>
        this.policyPremiumRecalculationService.getPolicyScheduleRecalculation(
          policy.policyUniqueRef,
          {
            paymentDay: recalculateScheduleProps.paymentDay,
            earlyPayment: true,
          },
        ).pipe(
          map((scheduleRecalculation) => policyActions.recalculateScheduleEarlyPaymentSuccess({ 
            scheduleRecalculation,
          })),
        ),
      ),
    );
  });

  cancelPolicy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyActions.cancelPolicy),
      concatLatestFrom(() => [ this.store.select(PolicySelectors.selectPolicy) ]),
      switchMap(([_, policy]) =>
        this.cancellationService.cancelPolicy(
          policy.policyUniqueRef,
          _.reason.reason,
          _.provider?.name, _.additionalComments).pipe(
          map((response) => response.processed ? 
            policyActions.cancelPolicySuccess() : 
            policyActions.cancelPolicyFailure()),
          catchError(() => of(policyActions.cancelPolicyFailure())))));
  });

  fetchPolicyDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        policyActions.fetchPolicyDocuments,
        RenewalActions.startRenewalJourney,
      ),
      concatLatestFrom(() => this.store.select(PolicySelectors.selectPolicy)),
      switchMap(([_, policy]) =>
        this.otherDocumentsService.getPolicyOtherDocuments(policy.policyUniqueRef).pipe(
          map((response) => policyActions.fetchPolicyDocumentsSuccess({
            policyDocuments: response.results,
          })),
        ),
      ),
    );
  });
  
  fetchHorseColours$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        policyActions.fetchHorseColours,
      ),
      concatLatestFrom(() => this.store.select(PolicySelectors.selectHorseColours)),
      filter(([_,horseColours]) => horseColours === null),
      switchMap((_) =>
        this.policyService.getHorseColours().pipe(
          map((response) => policyActions.fetchHorseColoursSuccess({
            horseColours: response,
          })),
        ),
      ),
    );
  });

  openPaymentFailureModal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyActions.openPaymentFailureModal),
      tap(() => {
        const paymentFailureComponent = this.modalService.open(PaymentFailureComponent, {
          centered: true,
          size: 'md',
        });
  
        paymentFailureComponent.componentInstance.retryPaymentJourney = true;
      }),
    );
  }, { dispatch: false });
  

  constructor(
    private store: Store,
    private actions$: Actions,
    private policyService: PolicyService,
    private policyMethods: PolicyMethods,
    private claimService: ClaimService,
    private staticDocumentService: StaticDocumentService,
    private uploadedDocumentService: SupportingDocumentsService,
    private cancellationService: CancellationService,
    private otherDocumentsService: PolicyOtherDocumentsService,
    private policyDirectDebitService: PolicyDirectDebitService,
    private policyPremiumRecalculationService: PolicyPremiumRecalculationService,
    private toastService: ToastService,
    private modalService: NgbModal,
  ) {}
}