import { ButtonComponent } from '@afi/ux';
import { CommonModule } from '@angular/common';
import { Component, Input, Optional } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-payment-failure',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    RouterModule,
    ButtonComponent,
  ],
  templateUrl: './payment-failure.component.html',
  styleUrl: './payment-failure.component.scss',
})
export class PaymentFailureComponent {
  constructor(
    private router: Router,
    @Optional() private activeModal?: NgbActiveModal,
  ) {}
  @Input() url: string[];
  @Input() retryPaymentJourney: boolean = false;
  readonly faChevronRight = faChevronRight;

  retryPayment(): void {
    if(this.retryPaymentJourney && this.activeModal) {
      this.activeModal.close();
    } else {
      this.router.navigate(this.url);
    }
  }
}
