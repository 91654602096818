import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationEnd,
  PRIMARY_OUTLET,
  Router,
} from '@angular/router';
import { createEffect } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, share, switchMap, tap } from 'rxjs/operators';

export const BackgroundGuardBackgoundColorParam = 'bodyBackground';

@Injectable()
export class UiEffects {
  static DefaultTitle = 'PAGETITLE.DEFAULT';

  constructor(
    private router: Router,
    private titleService: Title,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  readonly navEnd$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    share(),
  );

  readonly navEndRoute$ = this.navEnd$.pipe(
    map(() => this.activatedRoute),
    map((route) => {
      while (route.firstChild) {
        route = route.firstChild;
      }
      return route;
    }),
    filter((route) => route.outlet === PRIMARY_OUTLET),
    share(),
  );

  setPageTitle$ = createEffect(
    () => {
      return this.navEndRoute$.pipe(
        switchMap((route) => route.data),
        switchMap((data) =>
          this.translate.get(data.title || UiEffects.DefaultTitle),
        ),
        tap((title) => {
          this.titleService.setTitle(title);
        }),
      )},
    { dispatch: false },
  );

  setPageBackgroundColor$ = createEffect(
    () => {
      return this.navEndRoute$.pipe(
        switchMap((route) => route.data),
        filter((data) => !!data),
        tap((data) => 
          this.document.body.style.setProperty(
            'background-color',
            data[BackgroundGuardBackgoundColorParam],
          ),
        ),
      )},
    { dispatch: false },
  );

  scrollToTop$ = createEffect(
    () => { return this.navEnd$.pipe(tap(() => window.scrollTo(0, 0))) },
    { dispatch: false },
  );
}
