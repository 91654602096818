import {
  RepeatMedsClaimCondition,
} from "@features/claims/_repeat-medication/interfaces/repeat-medication-claim-condition.interface";
import { createAction, props } from "@ngrx/store";


const setClaimDetails = createAction(
  '[Repeat Medication] Set Claim Details',
  props<{
    condition: RepeatMedsClaimCondition,
    treatmentFrom: Date,
    treatmentTo: Date,
    medications: string[],
    amount: number
  }>(),
);
const updatePrescriptionFiles = createAction(
  '[Repeat Medication] Update Prescription Files',
  props<{ files: File[] }>(),
);

const setHasPrescription = createAction(
  '[Repeat Medication] Set Has Prescription',
  props<{ hasPrescription: boolean | null }>(),
);

const updateInvoiceFiles = createAction(
  '[Repeat Medication] Update Invoice Files',
  props<{ files: File[] }>(),
);

export const repeatMedicationActions = {
  setClaimDetails,
  updatePrescriptionFiles,
  setHasPrescription,
  updateInvoiceFiles,
};