import { ActionReducerMap, combineReducers } from "@ngrx/store";
import { ClaimState } from "../claim-state";
import { claimDetailReducer } from "./claim-detail.reducer";
import { repeatMedicationReducer } from "./repeat-medication.reducer";

export const reducers: ActionReducerMap<ClaimState> = {
  claimDetail: claimDetailReducer,
  repeatMedication: repeatMedicationReducer,
};
  
export const rootReducer = combineReducers(reducers);