import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import {
  initialReviewCoverState,
  ReviewCoverState,
} from '../review-cover-state';
import { reviewCoverActions } from '../actions/review-cover.actions';
import { changeCoverActions } from '../actions/change-cover.actions';
import { RenewalActions } from '../actions/renewal.actions';
import { coreActions } from '@core/store/actions/core.actions';

const reducer: ActionReducer<ReviewCoverState, Action> = createReducer(
  initialReviewCoverState,
  on(
    reviewCoverActions.recalculatePremium,
    RenewalActions.fetchDiscountSuccess,
    (state): ReviewCoverState => ({
      ...state,
      premiumRecalculation: null,
      changeCoverJourney: null,
      loading: true,
      error: false,
    })),
  on(
    reviewCoverActions.recalculatePremiumSuccess,
    RenewalActions.fetchDiscountPriceSuccess,
    (state, { premiumRecalculation }): ReviewCoverState => ({
      ...state,
      premiumRecalculation,
      changeCoverJourney: null,
      loading: false,
      error: false,
    }),
  ),
  on(reviewCoverActions.recalculatePremiumError, (state): ReviewCoverState => ({
    ...state,
    premiumRecalculation: null,
    changeCoverJourney: null,
    loading: false,
    error: true,
  })),
  on(
    reviewCoverActions.updateCover,
    (state): ReviewCoverState => ({
      ...state,
      loading: true,
      error: false,
    }),
  ),
  on(
    reviewCoverActions.updateCoverSuccess,
    (state): ReviewCoverState => ({
      ...state,
      loading: false,
      error: false,
      complete: true,
    }),
  ),
  on(
    reviewCoverActions.updateCoverError,
    (state): ReviewCoverState => ({
      ...state,
      loading: false,
      error: true,
    }),
  ),
  on(
    reviewCoverActions.processRefund,
    (state): ReviewCoverState => ({
      ...state,
      loading: true,
      error: false,
    }),
  ),
  on(
    reviewCoverActions.processRefundError,
    (state): ReviewCoverState => ({
      ...state,
      loading: false,
      error: true,
    }),
  ),
  on(
    reviewCoverActions.updateChangeCoverJourney,
    (state, { changeCoverJourney }): ReviewCoverState => ({
      ...state,
      changeCoverJourney,
    }),
  ),
  on(
    reviewCoverActions.shouldExpand,
    (state, { shouldExpand }): ReviewCoverState => ({
      ...state,
      shouldExpand,
    }),
  ),
  on(
    changeCoverActions.resetState,
    RenewalActions.startRenewalJourney,
    (): ReviewCoverState => ({...initialReviewCoverState}),
  ),

  on(
    RenewalActions.applyRenewalDiscount,
    (state): ReviewCoverState => ({
      ...state,
      loading: true,
      error: false,
    }),
  ),

  on(
    RenewalActions.applyRenewalDiscountSuccess,
    (state): ReviewCoverState => ({
      ...state,
      loading: false,
      error: false,
      complete: true,
    }),
  ),

  on(
    RenewalActions.applyRenewalDiscountFailure,
    (state): ReviewCoverState => ({
      ...state,
      loading: false,
      error: true,
    }),
  ),
  
  on(coreActions.resetStore, 
    (): ReviewCoverState => ({
      ...initialReviewCoverState,
    })),
    
  on(reviewCoverActions.setPatchPolicy, 
    (state, { patchPolicy }): ReviewCoverState => ({
      ...state,
      patchPolicy,
    })),
  
  on(
    reviewCoverActions.coverPaymentFailure,
    (state): ReviewCoverState => ({
      ...state,
      loading: false,
    }),
  ),
);

export const reviewCoverReducer = (state: ReviewCoverState, action: Action) => 
  reducer(state, action);
