import { ClaimDetailState, initialClaimDetailState } from "./claim-detail-state";
import { initialRepeatMedicationState, RepeatMedicationState } from "./repeat-medication-state";

export const claimStateName = 'claim';

export interface ClaimState {
  claimDetail: ClaimDetailState,
  repeatMedication: RepeatMedicationState
}

export const initialClaimState: ClaimState = {
  claimDetail: initialClaimDetailState,
  repeatMedication: initialRepeatMedicationState,
};