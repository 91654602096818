import {
  PremiumRecalculation,
} from "@features/policies/interfaces/policy-premium-recalculation.interface";
import { ChangeCoverJourney } from "../enums/change-cover-journey.enum";

export const ReviewCoverStateName = 'reviewCover';

export interface ReviewCoverState {
  premiumRecalculation: PremiumRecalculation | null,
  changeCoverJourney: ChangeCoverJourney | null,
  showLowCoverMessage: boolean,
  error: boolean,
  loading: boolean,
  shouldExpand: boolean,
  complete: boolean,
  patchPolicy: any,
}

export const initialReviewCoverState: ReviewCoverState = {
  premiumRecalculation: null,
  changeCoverJourney: null,
  showLowCoverMessage: false,
  error: false,
  loading: true,
  shouldExpand: false,
  complete: false,
  patchPolicy: null,
};