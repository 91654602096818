<div class="p-3">
    <h1 class="fs-2 fw-bold text-start px-1" data-testid="payemnt-thank-you-heading" translate>
        AUTH.COMMON.PAYMENTFAILURE.HEADING
    </h1>
    <div class="py-3 text-start" [innerHtml]="'AUTH.COMMON.PAYMENTFAILURE.INFO'| translate">
    </div>
    <div class="d-flex flex-column gap-3">
        <afi-button variant="primary" (click)="retryPayment()" chevron="end" fullWidth="true">
            {{ 'AUTH.COMMON.PAYMENTFAILURE.TRYAGAIN' | translate}}
        </afi-button>
    </div>
</div>
