import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PolicyHolderState, PolicyHolderStateName } from '../state';
import { ClientDetailsEnum } from '@features/account-details/enums/client-details.enum';
import { PhoneEditStates } from '@features/account-details/enums/phone-edit-states.enum';
import { AddressEditState } from '@features/account-details/enums/address-edit-state.enum';
import { selectIsFeatureEnabled } from '@shared/feature-flags/store/feature-flag.selector';
import { FeatureFlags } from '@shared/enums/feature-flags.enum';

const selectPolicyHolderState = createFeatureSelector<PolicyHolderState>(
  PolicyHolderStateName,
);

const selectPolicyHolder = createSelector(
  selectPolicyHolderState,
  (state) => state.policyHolder,
);

const selectHasAnyActivePolicy = createSelector(
  selectPolicyHolderState,
  (state) => state.hasAnyActivePolicy,
);

const selectHasActiveLiveAnnualPolicy = createSelector(
  selectPolicyHolderState,
  (state) => state.hasActiveLiveAnnualPolicy,
);

const selectHasActiveHorseRiderPolicy = createSelector(
  selectPolicyHolderState,
  (state) => state.hasActiveHorseRiderPolicy,
);

const selectHasLimitedAccessPolicy = createSelector(
  selectPolicyHolderState,
  (state) => state.hasActiveLimitedAccessPolicy,
);

const selectHasMultipleActiveMonthlyPolicies = createSelector(
  selectPolicyHolderState,
  (state) => state.hasMultipleActiveMonthlyPolicies,
);

const selectPetNames = createSelector(
  selectPolicyHolderState,
  (state) => state.petNames,
);

const selectPetCount = createSelector(
  selectPetNames,
  (names) => names?.length || 0,
);

const selectMonthlyPetNames = createSelector(
  selectPetNames,
  (names) => names.filter(x => x.monthlyPolicy).map(x => x.name),
);

const selectPetName = createSelector(
  selectPetNames,
  (names) => names.length > 0 ? names[names.length - 1].name : null,
);

const selectSearchAddress = createSelector(
  selectPolicyHolderState,
  (state) => state.searchAddress,
);

const selectRetrieveAddress = createSelector(
  selectPolicyHolderState,
  (state) => state.retrieveAddress,
);

const selectFirstName = createSelector(
  selectPolicyHolder,
  (state) => state?.forename,
);

const selectMobileNumber = createSelector(
  selectPolicyHolderState,
  (state) => state.policyHolder.mobilePhone,
);

const selectEveningNumber = createSelector(
  selectPolicyHolderState,
  (state) => state.policyHolder.eveningNumber,
);

const selectHomeNumber = createSelector(
  selectPolicyHolderState,
  (state) => state.policyHolder.phoneNumber,
);

const selectEditingField = createSelector(
  selectPolicyHolderState,
  (state) => state.editing,
);

const selectIsEditingPhone = createSelector(
  selectPolicyHolderState,
  (state): PhoneEditStates | null => {
    if (state.editing === ClientDetailsEnum.Mobile) {
      return state.editingStatus as PhoneEditStates;
    }
    return null;
  },
);

const selectIsEditingAddress = createSelector(
  selectPolicyHolderState,
  (state): AddressEditState | null => {
    if (state.editing === ClientDetailsEnum.Address) {
      return state.editingStatus as AddressEditState;
    }
    return null;
  },
);

const selectPremiumRecalculation = createSelector(
  selectPolicyHolderState,
  (state) => state.premiumRecalculation,
);

const selectIsJoiiRegistered = createSelector(
  selectPolicyHolderState,
  (state) => state.isJoiiRegistered,
);

const selectRestrictions = createSelector(
  selectPolicyHolderState,
  (state) => state.restrictions,
);

const selectHasComplaint = createSelector(
  selectRestrictions,
  (state) => state?.complaint,
);

const selectIsJoiiRemotePrescribingEligible = createSelector(
  selectRestrictions,
  (state) => state?.joiiRemotePrescribingEligible,
);

const selectConsents = createSelector(
  selectPolicyHolderState,
  (state) => state?.consents,
);

const selectIsJoiiRemotePrescribingConsented = createSelector(
  selectConsents,
  (state) => state?.joiiRemotePrescribing,
);

const selectPrizeDrawWinnerPolicyPetNames = createSelector(
  selectIsFeatureEnabled(FeatureFlags.PrizeDraw),
  selectPolicyHolderState,
  (isFeatureEnabled: boolean, state: PolicyHolderState) => {
    return !isFeatureEnabled
      ? []
      : state.petNames
        .filter(pet => pet.prizeDrawWinner)
        .map(pet => pet.name);
  },
); 

const selectPrizeDrawerWinnerCount = createSelector(
  selectPrizeDrawWinnerPolicyPetNames,
  (winningPetNames: string[]) => {
    return winningPetNames.length;
  },
);

const selectPaymentResultDisplayed = createSelector(
  selectPolicyHolderState,
  (state: PolicyHolderState) => state.paymentResultDisplayed);

export const policyHolderSelectors = {
  selectPolicyHolderState,
  selectPolicyHolder,
  selectHasAnyActivePolicy,
  selectHasActiveLiveAnnualPolicy,
  selectHasActiveHorseRiderPolicy,
  selectHasLimitedAccessPolicy,
  selectSearchAddress,
  selectRetrieveAddress,
  selectFirstName,
  selectPetNames,
  selectPetCount,
  selectMonthlyPetNames,
  selectPetName,
  selectMobileNumber,
  selectEveningNumber,
  selectHomeNumber,
  selectEditingField,
  selectIsEditingPhone,
  selectHasMultipleActiveMonthlyPolicies,
  selectIsEditingAddress,
  selectPremiumRecalculation,
  selectIsJoiiRegistered,
  selectRestrictions,
  selectHasComplaint,
  selectIsJoiiRemotePrescribingEligible,
  selectConsents,
  selectIsJoiiRemotePrescribingConsented,
  selectPrizeDrawWinnerPolicyPetNames,
  selectPrizeDrawerWinnerCount,
  selectPaymentResultDisplayed,
};
