import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CoverSelectionSelectors } from '../selectors/cover-selection.selector';
import { reviewCoverActions } from '../actions/review-cover.actions';
import { PolicyPremiumRecalculationService } from 
  '@features/policies/services/policy-premium-recalculation.service';
import { of } from 'rxjs';
import { reviewCoverSelectors } from '../selectors/review-cover.selector';
import { ChangeCoverService } from '@features/change-cover/services/change-cover.service';
import { PolicyPaymentFrequency } from '@features/policies/enums/policy-payment-frequency.enum';
import { PolicyService } from '@features/policies/services/policy.service';
import { PolicyPaymentType } from '@features/policies/enums/policy-payment-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { changeCoverActions } from '../actions/change-cover.actions';
import { VetFeesExampleComponent } from
  '@features/change-cover/components/vet-fees-example/vet-fees-example.component';
import { CoverType } from '@features/change-cover/enums/cover-type.enum';
import { PolicySelectors } from '@features/policies/store/policy.selectors';
import { RenewalSelectors } from '../selectors/renewal.selectors';
import { policyActions } from '@features/policies/store/policy.actions';

@Injectable()
export class ReviewCoverEffects {
  recalculatePremium$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reviewCoverActions.recalculatePremium),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectPolicy),
        this.store.select(CoverSelectionSelectors.selectChangeCoverQuote),
      ]),
      switchMap(([_props, policy, changeCoverQuote]) =>
        this.premiumRecalculationService
          .getPolicyPremiumRecalculation(policy.policyUniqueRef, {
            productId: changeCoverQuote.productId.toString(),
            excess: changeCoverQuote.excess.toString(),
          })
          .pipe(
            map((premiumRecalculation) =>
              reviewCoverActions.recalculatePremiumSuccess({
                premiumRecalculation,
              }),
            ),
            catchError(() => of(reviewCoverActions.recalculatePremiumError())),
          ),
      ),
    );
  });

  setPatchPolicy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reviewCoverActions.recalculatePremium),
      concatLatestFrom(() => [
        this.store.select(CoverSelectionSelectors.selectChangeCoverQuote),
        this.store.select(CoverSelectionSelectors.selectCoverType),
        this.store.select(CoverSelectionSelectors.selectCoverProduct),
        this.store.select(CoverSelectionSelectors.selectLimitType),
        this.store.select(RenewalSelectors.selectUsingJourney),
      ]),
      switchMap(
        ([
          _,
          changeCoverQuote,
          coverType,
          coverProduct,
          limitType,
          usingRenewalJourney,
        ]) => {
          const patchPolicy = usingRenewalJourney && coverType === null
            ? {
              excess: changeCoverQuote.excess,
            }
            : {
              productId: changeCoverQuote.productId,
              excess: changeCoverQuote.excess,
              accidentOnly: coverType === CoverType.AccidentOnly,
              minVetFeeCover: coverProduct ? coverProduct : 0,
              limitType,
              productName: changeCoverQuote.productType,
            };
          return of(reviewCoverActions.setPatchPolicy({patchPolicy: patchPolicy}));
        },
      ),
    );
  });


  changeCoverJourney$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reviewCoverActions.recalculatePremiumSuccess),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectPolicy),
        this.store.select(reviewCoverSelectors.selectPremiumRecalculation),
      ]),
      map(([_, policy, premiumRecalculation]) => 
        reviewCoverActions.updateChangeCoverJourney({
          changeCoverJourney:
              policy.paymentFrequency === PolicyPaymentFrequency.Annually
                ? this.changeCoverService.getAnnualChangeCoverJourney(
                  policy,
                  premiumRecalculation.expectedPayment,
                )
                : this.changeCoverService.getMonthlyChangeCoverJourney(
                  premiumRecalculation.expectedPayment,
                  premiumRecalculation.forceRefund,
                ),
        }),
      ),
    ); },
  );

  annualRefund$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reviewCoverActions.processRefund),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectPolicy),
        this.store.select(reviewCoverSelectors.selectPremiumRecalculation),
      ]),
      filter(([_, policy, _premiumRecalculation]) => 
        policy.paymentFrequency === PolicyPaymentFrequency.Annually),
      switchMap(([_, policy, premiumRecalculation]) =>
        this.policyService
          .creditCardRefund({
            policyNoLong: policy.policyUniqueRef,
            expectedPaymentUniqueId: premiumRecalculation.expectedPaymentGuid,
            amount: Math.abs(premiumRecalculation.expectedPayment),
            paymentType: PolicyPaymentType.CoverChange,
          })
          .pipe(
            map((success) => {
              if (success) {
                return reviewCoverActions.processRefundSuccess();
              } else {
                return reviewCoverActions.processRefundError();
              }
            }),
            catchError(() => of(reviewCoverActions.processRefundError())),
          ),
      ),
    ); },
  );

  monthlyRefund$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reviewCoverActions.processRefund),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectPolicy),
        this.store.select(reviewCoverSelectors.selectPremiumRecalculation),
      ]),
      filter(([_, policy, _premiumRecalculation]) => 
        policy.paymentFrequency === PolicyPaymentFrequency.Monthly),
      switchMap(([_, policy, premiumRecalculation]) =>
        this.policyService
          .debitOrderRefund({
            policyNoLong: policy.policyUniqueRef,
            expectedPaymentUniqueId: premiumRecalculation.expectedPaymentGuid,
            amount: Math.abs(premiumRecalculation.expectedPayment),
            paymentType: PolicyPaymentType.CoverChange,
          })
          .pipe(
            map((response) => {
              if (response !== 0) {
                return reviewCoverActions.processRefundSuccess();
              } else {
                return reviewCoverActions.processRefundError();
              }
            }),
            catchError(() => of(reviewCoverActions.processRefundError())),
          ),
      ),
    );},
  );

  monthlyRefundError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(reviewCoverActions.processRefundError),
        concatLatestFrom(
          () => this.store.select(PolicySelectors.selectPolicy),
        ),
        filter(([_, policy]) => policy.paymentFrequency === PolicyPaymentFrequency.Monthly),
        tap(() => this.modalService.dismissAll()),
      ); },
    { dispatch: false },
  );

  updateCover$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        reviewCoverActions.updateCover,
        reviewCoverActions.paymentSuccess,
        reviewCoverActions.processRefundSuccess,
      ),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectPolicy),
        this.store.select(reviewCoverSelectors.selectPatchPolicy),
      ]),
      switchMap(
        ([
          _,
          policy,
          patchPolicy,
        ]) => {
          return this.policyService
            .patchPolicy(policy.policyUniqueRef, patchPolicy)
            .pipe(
              map((response) => {
                if(response[0].createdId != 0) {
                  return reviewCoverActions.updateCoverSuccess();
                } else {
                  return reviewCoverActions.updateCoverError();
                }
              }),
              catchError(() => of(reviewCoverActions.updateCoverError())),
            );
        },
      ),
    );
  });

  policyDetailsRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(reviewCoverActions.successModalClosed),
        concatLatestFrom(() => this.store.select(PolicySelectors.selectPolicy)),
        tap(([_, policy]) => {
          this.router.navigate([
            '/logged-in',
            'policies',
            'policies',
            policy.policyUniqueRef,
          ]).then(() => {
            return changeCoverActions.resetState();
          });
        }),
      ); },
    { dispatch: false },
  );

  displayVetFeesExample$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reviewCoverActions.openVetFeesExample),
      concatLatestFrom(() =>
        this.store.select(CoverSelectionSelectors.selectChangeCoverQuote),
      ),
      tap(([_, coverQuote]) => {
        this.modalService.dismissAll();
        const modal = this.modalService.open(VetFeesExampleComponent, { centered: true });
        modal.componentInstance.isV3orV4 = 
        this.changeCoverService.isProductV3orV4(coverQuote.productVersion);
        modal.componentInstance.productName = coverQuote.productName;
      }),
    ); },
  { dispatch: false },
  );

  coverPaymentFailureEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reviewCoverActions.coverPaymentFailure),
      map(() => {
        return policyActions.openPaymentFailureModal();
      }),
    );
  });

  constructor(
    private policyService: PolicyService,
    private premiumRecalculationService: PolicyPremiumRecalculationService,
    private changeCoverService: ChangeCoverService,
    private actions$: Actions,
    private store: Store,
    private modalService: NgbModal,
    private router: Router,
  ) {}
}
