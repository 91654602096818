import { ActionReducer, Action, createReducer, on } from "@ngrx/store";
import { ClaimDetailState, initialClaimDetailState } from "../claim-detail-state";
import { claimDetailActions } from "../actions/claim-detail.actions";

const reducer: ActionReducer<ClaimDetailState, Action> = createReducer(
  initialClaimDetailState,
  on(claimDetailActions.createClaim, (state): ClaimDetailState => ({ ...state, loading: true })),
  on(claimDetailActions.createClaimSuccess, (state): ClaimDetailState => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(claimDetailActions.createClaimFailure, (state, { error }): ClaimDetailState => ({
    ...state,
    loading: false,
    error,
  })),

  on(claimDetailActions.repeatMedPageLoaded, (state): ClaimDetailState => ({ 
    ...state, 
    loading: true, 
  })),

  on(
    claimDetailActions.loadPolicyConditionsSuccess, 
    (state, { conditions }): ClaimDetailState => ({
      ...state,
      loading: false,
      conditions,
    })),
  
  on(claimDetailActions.loadPolicyConditionsFailure, (state, { error }): ClaimDetailState => ({
    ...state,
    loading: false,
    error,
  })),
);

export const claimDetailReducer = (state: ClaimDetailState, action: Action) => 
  reducer(state, action);