import { createAction, props } from '@ngrx/store';
import { HttpEvent } from '@angular/common/http';
import { 
  RepeatMedsClaimCondition,
} from '@features/claims/_repeat-medication/interfaces/repeat-medication-claim-condition.interface';

export const createClaim = createAction(
  '[Claim Detail] Create Claim',
  props<{ formData: FormData; isV2Claim: boolean }>(),
);

export const createClaimSuccess = createAction(
  '[Claim Detail] Create Claim Success',
  props<{ response: HttpEvent<unknown> }>(),
);

export const createClaimFailure = createAction(
  '[Claim Detail] Create Claim Failure',
  props<{ error: unknown }>(),
);

export const repeatMedPageLoaded = createAction(
  '[Claim Detail] Repeat Medication Page Loaded',
  props<{ riskType?: string }>(),
);

export const loadPolicyConditionsSuccess = createAction(
  '[Claim Detail] Load Policy Conditions Success',
  props<{ conditions: RepeatMedsClaimCondition[] }>(),
);

export const loadPolicyConditionsFailure = createAction(
  '[Claim Detail] Load Policy Conditions Failure',
  props<{ error: unknown }>(),
);

export const claimDetailActions = {
  createClaim,
  createClaimSuccess,
  createClaimFailure,
  loadPolicyConditionsSuccess,
  loadPolicyConditionsFailure,
  repeatMedPageLoaded,
};
