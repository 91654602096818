import { ChangeCoverJourney } from '@features/change-cover/enums/change-cover-journey.enum';
import { PremiumRecalculation } from '@features/policies/interfaces/policy-premium-recalculation.interface';
import { createAction, props } from '@ngrx/store';

const recalculatePremium = createAction(
  '[Review Cover] Recalculate Premium',
);

const recalculatePremiumSuccess = createAction(
  '[Review Cover] Recalculate Premium Success',
  props<{ premiumRecalculation: PremiumRecalculation }>(),
);

const recalculatePremiumError = createAction(
  '[Review Cover] Recalculate Premium Error',
);

const updateChangeCoverJourney = createAction(
  '[Review Cover] Update current payment journey',
  props<{ changeCoverJourney: ChangeCoverJourney }>(),
);

const continueButtonClicked = createAction(
  '[Review Cover] Continue button clicked',
);

const updateCover = createAction(
  '[Review Cover] Update Cover',
);

const updateCoverSuccess = createAction(
  '[Review Cover] Update Cover Success',
);

const updateCoverError = createAction(
  '[Review Cover] Update Cover Error',
);

const processRefund = createAction(
  '[Review Cover] Process Refund',
);

const processRefundSuccess = createAction(
  '[Review Cover] Process Refund Success',
);

const processRefundError = createAction(
  '[Review Cover] Process Refund Error',
);

const paymentSuccess = createAction(
  '[Review Cover] Payment Success',
);

const paymentError = createAction(
  '[Review Cover] Payment Error',
);

const successModalClosed = createAction(
  '[Review Cover] Success Modal Closed',
);

const shouldExpand = createAction(
  '[Review Cover] Expand Additional Benefits',
  props<{shouldExpand: boolean}>(),
);

const openVetFeesExample = createAction(
  '[Review Cover] Open Vet Fees Example',
);

const setPatchPolicy = createAction(
  '[Review Cover] Set Patch Policy Object',
  props<{patchPolicy: any}>(),
);

const coverPaymentFailure = createAction(
  '[Review Cover] Payment Failure',
);

export const reviewCoverActions = {
  recalculatePremium,
  recalculatePremiumSuccess,
  recalculatePremiumError,
  updateChangeCoverJourney,
  continueButtonClicked,
  updateCover,
  updateCoverSuccess,
  updateCoverError,
  processRefund,
  processRefundSuccess,
  processRefundError,
  paymentSuccess,
  paymentError,
  successModalClosed,
  shouldExpand,
  openVetFeesExample,
  setPatchPolicy,
  coverPaymentFailure,
};
