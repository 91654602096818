import { PolicyPaymentFrequency } from '@features/policies/enums/policy-payment-frequency.enum';
import { PolicyPaymentStatus } from '@features/policies/enums/policy-payment-status.enum';
import { Policy } from '@interfaces/policy';
import { MockPolicyPaymentsMap } from './policy-payments';

const nextYear = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365);

const LiveCatOverduePolicy: Policy = {
  policyUniqueRef: 'PD1234567',
  previousPolicyRef: undefined,
  coverLevel: 'Prestige',
  coverCategory: 'Lifetime',
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(),
  ipidUri:
    'https://www.animalfriends.co.uk/app/policydocuments/claimforms/pet-claim.pdf',
  policyUri:
    'https://www.animalfriends.co.uk/app/policydocuments/claimforms/pet-claim.pdf',
  termsUri:
    'https://www.animalfriends.co.uk/app/policydocuments/claimforms/pet-claim.pdf',
  vetFeeCapsUri:
    'https://www.animalfriends.co.uk/app/policydocuments/claimforms/pet-claim.pdf',
  pet: {
    petName: 'Bumble',
    petBreed: 'Moggie',
    petDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 3),
    petColour: 'Red',
    petGender: 'Male',
    heightHands: null,
    heightInches: null,
    riderDOB: null,
    riderFirstName: null,
    riderSurname: null,
    type: 'cat',
    horseActivity: null,
    horsePostcode: null,
    microchipNo: '1234567890',
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: nextYear,
  renewalDate: nextYear,
  coPayment: 0,
  excess: 99,
  monthlyTotal: 11.89,
  annualTotal: 142.68,
  outstandingPayment: 11.89,
  paymentStatus: PolicyPaymentStatus.Overdue,
  paymentFrequency: PolicyPaymentFrequency.Monthly,
  preferredPaymentDay: 31,
  customerActionRequired: false,
  voluntaryExcess: null,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const LiveDogAnnualRenewalDuePolicy: Policy = {
  policyUniqueRef: 'PY7654321',
  previousPolicyRef: undefined,
  coverLevel: 'Accident',
  coverCategory: 'Accident Only',
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: 'http://www.animalfriends.co.uk',
  vetFeeCapsUri: 'http://www.animalfriends.co.uk',
  pet: {
    petName: 'Lilly',
    petBreed: 'St Bernard',
    petDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 10),
    petColour: 'White',
    petGender: 'Female',
    heightHands: null,
    heightInches: null,
    riderDOB: null,
    riderFirstName: null,
    riderSurname: null,
    type: 'dog',
    horseActivity: null,
    horsePostcode: null,
    microchipNo: '1234567890',
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  renewalDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  coPayment: 0.35,
  excess: 99,
  monthlyTotal: 9.4,
  annualTotal: 112.8,
  outstandingPayment: 112.8,
  paymentStatus: PolicyPaymentStatus.RenewalDue,
  paymentFrequency: PolicyPaymentFrequency.Annually,
  preferredPaymentDay: 15,
  customerActionRequired: true,
  voluntaryExcess: null,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const LiveDogMonthlyRenewalDuePolicy: Policy= {
  policyUniqueRef: 'PY8654321',
  previousPolicyRef: undefined,
  coverLevel: 'Accident',
  coverCategory: 'Accident Only',
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: 'http://www.animalfriends.co.uk',
  vetFeeCapsUri: 'http://www.animalfriends.co.uk',
  pet: {
    petName: 'Lilly',
    petBreed: 'St Bernard',
    petDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 10),
    petColour: 'White',
    petGender: 'Female',
    heightHands: null,
    heightInches: null,
    riderDOB: null,
    riderFirstName: null,
    riderSurname: null,
    type: 'dog',
    horseActivity: null,
    horsePostcode: null,
    microchipNo: '1234567890',
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  renewalDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  coPayment: 0.35,
  excess: 99,
  monthlyTotal: 9.4,
  annualTotal: 112.8,
  outstandingPayment: 112.8,
  paymentStatus: PolicyPaymentStatus.RenewalDue,
  paymentFrequency: PolicyPaymentFrequency.Monthly,
  preferredPaymentDay: 15,
  customerActionRequired: true,
  voluntaryExcess: null,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const LiveCatAccidentOnlyUpToDatePolicy: Policy = {
  policyUniqueRef: 'PD1212121-2',
  previousPolicyRef: 'PD1212121-1',
  coverLevel: 'Accident',
  coverCategory: 'Accident Only',
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: undefined,
  vetFeeCapsUri: 'http://www.animalfriends.co.uk',
  pet: {
    petName: 'Sharron',
    petBreed: 'Moggie',
    petDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 6),
    petColour: 'Red',
    petGender: 'Female',
    heightHands: null,
    heightInches: null,
    riderDOB: null,
    riderFirstName: null,
    riderSurname: null,
    type: 'cat',
    horseActivity: null,
    horsePostcode: null,
    microchipNo: '1234567890',
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24),
  renewalDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 152),
  coPayment: 0,
  excess: 50,
  monthlyTotal: 20,
  annualTotal: 240,
  outstandingPayment: 0,
  paymentStatus: PolicyPaymentStatus.UpToDate,
  paymentFrequency: PolicyPaymentFrequency.Monthly,
  preferredPaymentDay: 28,
  customerActionRequired: false,
  voluntaryExcess: null,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const LiveCatE2EPolicy: Policy = {
  policyUniqueRef: 'PD1212123',
  previousPolicyRef: undefined,
  coverLevel: 'Max benefit - maxplus',
  coverCategory: 'Lifetime',
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: undefined,
  vetFeeCapsUri: 'http://www.animalfriends.co.uk',
  pet: {
    petName: 'Terraklaus',
    petBreed: 'Siberian',
    petDOB: new Date(2017, 2, 10),
    petColour: 'Tabby',
    petGender: 'Female',
    heightHands: null,
    heightInches: null,
    riderDOB: null,
    riderFirstName: null,
    riderSurname: null,
    type: 'cat',
    horseActivity: null,
    horsePostcode: null,
    microchipNo: null,
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24),
  renewalDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 152),
  coPayment: 0.2,
  excess: 99,
  monthlyTotal: 20,
  annualTotal: 240,
  outstandingPayment: 0,
  paymentStatus: PolicyPaymentStatus.UpToDate,
  paymentFrequency: PolicyPaymentFrequency.Monthly,
  preferredPaymentDay: 28,
  customerActionRequired: false,
  voluntaryExcess: null,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const LiveCatE2EV3Policy: Policy = {
  policyUniqueRef: 'PD1212129',
  previousPolicyRef: undefined,
  coverLevel: 'Max benefit - maxplus',
  coverCategory: 'Lifetime',
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: undefined,
  vetFeeCapsUri: 'http://www.animalfriends.co.uk',
  pet: {
    petName: 'Terraklaus',
    petBreed: 'Siberian',
    petDOB: new Date(2017, 2, 10),
    petColour: 'Tabby',
    petGender: 'Female',
    heightHands: null,
    heightInches: null,
    riderDOB: null,
    riderFirstName: null,
    riderSurname: null,
    type: 'cat',
    horseActivity: null,
    horsePostcode: null,
    microchipNo: null,
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24),
  renewalDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 152),
  coPayment: 0.2,
  excess: 99,
  monthlyTotal: 20,
  annualTotal: 240,
  outstandingPayment: 0,
  paymentStatus: PolicyPaymentStatus.UpToDate,
  paymentFrequency: PolicyPaymentFrequency.Monthly,
  preferredPaymentDay: 28,
  customerActionRequired: false,
  voluntaryExcess: null,
  productId: null,
  productVersion: 'V3 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const LiveCatAccidentOnlyOverduePolicy: Policy = {
  policyUniqueRef: 'PD2222222',
  previousPolicyRef: undefined,
  coverLevel: 'Accident',
  coverCategory: 'Accident Only',
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: undefined,
  vetFeeCapsUri: 'http://www.animalfriends.co.uk',
  pet: {
    petName: 'Molly',
    petBreed: 'Moggie',
    petDOB: new Date(2016, 1, 1),
    petColour: 'Grey',
    petGender: 'Female',
    heightHands: null,
    heightInches: null,
    riderDOB: null,
    riderFirstName: null,
    riderSurname: null,
    type: 'cat',
    horseActivity: null,
    horsePostcode: null,
    microchipNo: '1234567890',
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: nextYear,
  renewalDate: nextYear,
  coPayment: 0,
  excess: 50,
  monthlyTotal: 10,
  annualTotal: 120,
  outstandingPayment: 25.76,
  paymentStatus: PolicyPaymentStatus.Overdue,
  paymentFrequency: PolicyPaymentFrequency.Monthly,
  preferredPaymentDay: 24,
  customerActionRequired: true,
  voluntaryExcess: null,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const LiveHorsePolicy: Policy = {
  policyUniqueRef: 'HH1000001',
  previousPolicyRef: undefined,
  coverLevel: 'Standard',
  coverCategory: '',
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: undefined,
  vetFeeCapsUri: 'http://www.animalfriends.co.uk',
  pet: {
    petName: 'Halifax',
    petBreed: 'Dutch Warmblood',
    petDOB: new Date(2012, 4, 12),
    petColour: 'Bay',
    petGender: 'Female',
    heightHands: 17,
    heightInches: 1,
    riderDOB: null,
    riderFirstName: null,
    riderSurname: null,
    type: 'horse',
    horseActivity: null,
    horsePostcode: 'SP11 0HY',
    microchipNo: null,
    passportNo: '123ABC',
    horseOwnershipStatus: 'Owned',
    horsePurchaseDate: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 10),
  },
  expiryDate: nextYear,
  renewalDate: nextYear,
  coPayment: 0.2,
  excess: 99,
  monthlyTotal: 55.74,
  annualTotal: 668.88,
  outstandingPayment: 0,
  paymentStatus: PolicyPaymentStatus.UpToDate,
  paymentFrequency: PolicyPaymentFrequency.Monthly,
  preferredPaymentDay: 19,
  customerActionRequired: true,
  voluntaryExcess: null,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const E2EAdultRiderMonthlyPolicy: Policy = {
  policyUniqueRef: 'RY2200292',
  previousPolicyRef: undefined,
  coverLevel: 'Rider Only',
  coverCategory: null,
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: undefined,
  vetFeeCapsUri: undefined,
  pet: {
    petName: '',
    petBreed: '',
    petDOB: null,
    petColour: '',
    petGender: '',
    heightHands: null,
    heightInches: null,
    riderDOB: new Date(1969, 6, 17),
    riderFirstName: 'Felicity',
    riderSurname: 'Twotington-Burbidge',
    type: 'rider',
    horseActivity: null,
    horsePostcode: '',
    microchipNo: null,
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: nextYear,
  renewalDate: nextYear,
  coPayment: 0,
  excess: 99,
  monthlyTotal: 27.87,
  annualTotal: 334.44,
  outstandingPayment: 0,
  paymentStatus: PolicyPaymentStatus.UpToDate,
  paymentFrequency: PolicyPaymentFrequency.Monthly,
  preferredPaymentDay: 19,
  customerActionRequired: true,
  voluntaryExcess: null,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const E2EYoungRiderMonthlyPolicy: Policy = {
  policyUniqueRef: 'RY2200223',
  previousPolicyRef: undefined,
  coverLevel: 'Young rider',
  coverCategory: null,
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: undefined,
  vetFeeCapsUri: undefined,
  pet: {
    petName: '',
    petBreed: '',
    petDOB: null,
    petColour: '',
    petGender: '',
    heightHands: null,
    heightInches: null,
    riderDOB: new Date(2008, 8, 7),
    riderFirstName: 'Bunty',
    riderSurname: 'Twotington-Burbidge',
    type: 'rider',
    horseActivity: null,
    horsePostcode: '',
    microchipNo: null,
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: nextYear,
  renewalDate: nextYear,
  coPayment: 0,
  excess: 99,
  monthlyTotal: 27.87,
  annualTotal: 334.44,
  outstandingPayment: 0,
  paymentStatus: PolicyPaymentStatus.UpToDate,
  paymentFrequency: PolicyPaymentFrequency.Monthly,
  preferredPaymentDay: 19,
  customerActionRequired: true,
  voluntaryExcess: null,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const LiveRiderMonthlyPolicy: Policy = {
  policyUniqueRef: 'RY2200220',
  previousPolicyRef: undefined,
  coverLevel: 'Rider Only',
  coverCategory: null,
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: undefined,
  vetFeeCapsUri: undefined,
  pet: {
    petName: '',
    petBreed: '',
    petDOB: null,
    petColour: '',
    petGender: '',
    heightHands: null,
    heightInches: null,
    riderDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 38),
    riderFirstName: 'Peter',
    riderSurname: 'Spladge',
    type: 'rider',
    horseActivity: null,
    horsePostcode: '',
    microchipNo: null,
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: nextYear,
  renewalDate: nextYear,
  coPayment: 0,
  excess: 99,
  monthlyTotal: 27.87,
  annualTotal: 334.44,
  outstandingPayment: 0,
  paymentStatus: PolicyPaymentStatus.UpToDate,
  paymentFrequency: PolicyPaymentFrequency.Monthly,
  preferredPaymentDay: 19,
  customerActionRequired: true,
  voluntaryExcess: null,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const LiveYoungRiderMonthlyPolicy: Policy = {
  policyUniqueRef: 'RY2200221',
  previousPolicyRef: undefined,
  coverLevel: 'young rider',
  coverCategory: null,
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: undefined,
  vetFeeCapsUri: undefined,
  pet: {
    petName: '',
    petBreed: '',
    petDOB: null,
    petColour: '',
    petGender: '',
    heightHands: null,
    heightInches: null,
    riderDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 38),
    riderFirstName: 'John',
    riderSurname: 'Spladge',
    type: 'rider',
    horseActivity: null,
    horsePostcode: '',
    microchipNo: null,
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: nextYear,
  renewalDate: nextYear,
  coPayment: 0,
  excess: 99,
  monthlyTotal: 27.87,
  annualTotal: 334.44,
  outstandingPayment: 0,
  paymentStatus: PolicyPaymentStatus.UpToDate,
  paymentFrequency: PolicyPaymentFrequency.Monthly,
  preferredPaymentDay: 19,
  customerActionRequired: true,
  voluntaryExcess: null,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const LiveDogLifetimeAssurePolicy: Policy= {
  policyUniqueRef: 'PY99999',
  previousPolicyRef: undefined,
  coverLevel: 'Assure',
  coverCategory: 'Lifetime',
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: 'http://www.animalfriends.co.uk',
  vetFeeCapsUri: 'http://www.animalfriends.co.uk',
  pet: {
    petName: 'Doggy',
    petBreed: 'St Bernard',
    petDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 10),
    petColour: 'White',
    petGender: 'Female',
    heightHands: null,
    heightInches: null,
    riderDOB: null,
    riderFirstName: null,
    riderSurname: null,
    type: 'dog',
    horseActivity: null,
    horsePostcode: null,
    microchipNo: '1234567890',
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  renewalDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  coPayment: 0.35,
  excess: 99,
  monthlyTotal: 9.4,
  annualTotal: 112.8,
  outstandingPayment: 112.8,
  paymentStatus: PolicyPaymentStatus.RenewalDue,
  paymentFrequency: PolicyPaymentFrequency.Annually,
  preferredPaymentDay: 15,
  customerActionRequired: true,
  voluntaryExcess: null,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const LiveDogLifetimeAssurePlusPolicy: Policy = {
  policyUniqueRef: 'PY995559',
  previousPolicyRef: undefined,
  coverLevel: 'Assure Plus',
  coverCategory: 'Lifetime',
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: 'http://www.animalfriends.co.uk',
  vetFeeCapsUri: 'http://www.animalfriends.co.uk',
  pet: {
    petName: 'Doggy AssurePlus',
    petBreed: 'St Bernard',
    petDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 10),
    petColour: 'White',
    petGender: 'Female',
    heightHands: null,
    heightInches: null,
    riderDOB: null,
    riderFirstName: null,
    riderSurname: null,
    type: 'dog',
    horseActivity: null,
    horsePostcode: null,
    microchipNo: '1234567890',
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  renewalDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  coPayment: 0.35,
  excess: 99,
  monthlyTotal: 9.4,
  annualTotal: 112.8,
  outstandingPayment: 112.8,
  paymentStatus: PolicyPaymentStatus.RenewalDue,
  paymentFrequency: PolicyPaymentFrequency.Annually,
  preferredPaymentDay: 15,
  customerActionRequired: true,
  voluntaryExcess: null,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const LiveDogTimeLimitedPolicy: Policy= {
  policyUniqueRef: 'PY78787',
  previousPolicyRef: undefined,
  coverLevel: 'Basic Plus',
  coverCategory: 'Time Limited',
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: 'http://www.animalfriends.co.uk',
  vetFeeCapsUri: 'http://www.animalfriends.co.uk',
  pet: {
    petName: 'Time Limited Doggy',
    petBreed: 'St Bernard',
    petDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 10),
    petColour: 'White',
    petGender: 'Female',
    heightHands: null,
    heightInches: null,
    riderDOB: null,
    riderFirstName: null,
    riderSurname: null,
    type: 'dog',
    horseActivity: null,
    horsePostcode: null,
    microchipNo: '1234567890',
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  renewalDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  coPayment: 0.35,
  excess: 99,
  monthlyTotal: 9.4,
  annualTotal: 112.8,
  outstandingPayment: 112.8,
  paymentStatus: PolicyPaymentStatus.RenewalDue,
  paymentFrequency: PolicyPaymentFrequency.Annually,
  preferredPaymentDay: 15,
  customerActionRequired: true,
  voluntaryExcess: null,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const LiveDogSilverPolicy: Policy = {
  policyUniqueRef: 'PYSILVER99999',
  previousPolicyRef: undefined,
  coverLevel: 'Silver',
  coverCategory: 'Lifetime',
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: 'http://www.animalfriends.co.uk',
  vetFeeCapsUri: 'http://www.animalfriends.co.uk',
  pet: {
    petName: 'Silver Dog',
    petBreed: 'St Bernard',
    petDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 10),
    petColour: 'White',
    petGender: 'Female',
    heightHands: null,
    heightInches: null,
    riderDOB: null,
    riderFirstName: null,
    riderSurname: null,
    type: 'dog',
    horseActivity: null,
    horsePostcode: null,
    microchipNo: '1234567890',
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  renewalDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  coPayment: 0.35,
  excess: 99,
  monthlyTotal: 9.4,
  annualTotal: 112.8,
  outstandingPayment: 112.8,
  paymentStatus: PolicyPaymentStatus.RenewalDue,
  paymentFrequency: PolicyPaymentFrequency.Annually,
  preferredPaymentDay: 15,
  customerActionRequired: true,
  voluntaryExcess: 100,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const LiveDogSilverV3Policy: Policy = {
  policyUniqueRef: 'PYSILVER99998',
  previousPolicyRef: undefined,
  coverLevel: 'Silver',
  coverCategory: 'Lifetime',
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: 'http://www.animalfriends.co.uk',
  vetFeeCapsUri: 'http://www.animalfriends.co.uk',
  pet: {
    petName: 'Silver Dog',
    petBreed: 'St Bernard',
    petDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 10),
    petColour: 'White',
    petGender: 'Female',
    heightHands: null,
    heightInches: null,
    riderDOB: null,
    riderFirstName: null,
    riderSurname: null,
    type: 'dog',
    horseActivity: null,
    horsePostcode: null,
    microchipNo: '1234567890',
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  renewalDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  coPayment: 0.35,
  excess: 99,
  monthlyTotal: 9.4,
  annualTotal: 112.8,
  outstandingPayment: 112.8,
  paymentStatus: PolicyPaymentStatus.RenewalDue,
  paymentFrequency: PolicyPaymentFrequency.Annually,
  preferredPaymentDay: 15,
  customerActionRequired: true,
  voluntaryExcess: 100,
  productId: null,
  productVersion: 'V3 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const LiveDogGoldPolicy: Policy = {
  policyUniqueRef: 'PYGOLD99999',
  previousPolicyRef: undefined,
  coverLevel: 'Gold',
  coverCategory: 'Lifetime',
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: 'http://www.animalfriends.co.uk',
  vetFeeCapsUri: 'http://www.animalfriends.co.uk',
  pet: {
    petName: 'Gold Dog',
    petBreed: 'St Bernard',
    petDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 10),
    petColour: 'White',
    petGender: 'Female',
    heightHands: null,
    heightInches: null,
    riderDOB: null,
    riderFirstName: null,
    riderSurname: null,
    type: 'dog',
    horseActivity: null,
    horsePostcode: null,
    microchipNo: '1234567890',
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  renewalDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  coPayment: 0.35,
  excess: 99,
  monthlyTotal: 9.4,
  annualTotal: 112.8,
  outstandingPayment: 112.8,
  paymentStatus: PolicyPaymentStatus.RenewalDue,
  paymentFrequency: PolicyPaymentFrequency.Annually,
  preferredPaymentDay: 15,
  customerActionRequired: true,
  voluntaryExcess: 50,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const LiveDogPlatinumPolicy: Policy = {
  policyUniqueRef: 'PYPLAT99999',
  previousPolicyRef: undefined,
  coverLevel: 'Platinum',
  coverCategory: 'Lifetime',
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: 'http://www.animalfriends.co.uk',
  vetFeeCapsUri: 'http://www.animalfriends.co.uk',
  pet: {
    petName: 'Platinum Dog',
    petBreed: 'St Bernard',
    petDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 10),
    petColour: 'White',
    petGender: 'Female',
    heightHands: null,
    heightInches: null,
    riderDOB: null,
    riderFirstName: null,
    riderSurname: null,
    type: 'dog',
    horseActivity: null,
    horsePostcode: null,
    microchipNo: '1234567890',
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  renewalDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  coPayment: 0.35,
  excess: 99,
  monthlyTotal: 9.4,
  annualTotal: 112.8,
  outstandingPayment: 112.8,
  paymentStatus: PolicyPaymentStatus.RenewalDue,
  paymentFrequency: PolicyPaymentFrequency.Annually,
  preferredPaymentDay: 15,
  customerActionRequired: true,
  voluntaryExcess: 500,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const LiveDog10KPolicy: Policy = {
  policyUniqueRef: 'PY10K99999',
  previousPolicyRef: undefined,
  coverLevel: 'LIFETIME£10,000',
  coverCategory: 'Lifetime',
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: 'http://www.animalfriends.co.uk',
  vetFeeCapsUri: 'http://www.animalfriends.co.uk',
  pet: {
    petName: 'Platinum Dog',
    petBreed: 'St Bernard',
    petDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 10),
    petColour: 'White',
    petGender: 'Female',
    heightHands: null,
    heightInches: null,
    riderDOB: null,
    riderFirstName: null,
    riderSurname: null,
    type: 'dog',
    horseActivity: null,
    horsePostcode: null,
    microchipNo: '1234567890',
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  renewalDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  coPayment: 0.35,
  excess: 99,
  monthlyTotal: 9.4,
  annualTotal: 112.8,
  outstandingPayment: 112.8,
  paymentStatus: PolicyPaymentStatus.RenewalDue,
  paymentFrequency: PolicyPaymentFrequency.Annually,
  preferredPaymentDay: 15,
  customerActionRequired: true,
  voluntaryExcess: 500,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const LiveDog18KPolicy: Policy = {
  policyUniqueRef: 'PY18K99999',
  previousPolicyRef: undefined,
  coverLevel: 'LIFETIME£18,000',
  coverCategory: 'Lifetime',
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: 'http://www.animalfriends.co.uk',
  vetFeeCapsUri: 'http://www.animalfriends.co.uk',
  pet: {
    petName: 'Platinum Dog',
    petBreed: 'St Bernard',
    petDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 10),
    petColour: 'White',
    petGender: 'Female',
    heightHands: null,
    heightInches: null,
    riderDOB: null,
    riderFirstName: null,
    riderSurname: null,
    type: 'dog',
    horseActivity: null,
    horsePostcode: null,
    microchipNo: '1234567890',
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  renewalDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  coPayment: 0.35,
  excess: 99,
  monthlyTotal: 9.4,
  annualTotal: 112.8,
  outstandingPayment: 112.8,
  paymentStatus: PolicyPaymentStatus.RenewalDue,
  paymentFrequency: PolicyPaymentFrequency.Annually,
  preferredPaymentDay: 15,
  customerActionRequired: true,
  voluntaryExcess: 500,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

const AnnualNoOutstandingPaymentLiveDog18KPolicy: Policy = {
  policyUniqueRef: 'PY18K99999',
  previousPolicyRef: undefined,
  coverLevel: 'LIFETIME£18,000',
  coverCategory: 'Lifetime',
  status: 'Live',
  statusReason: null,
  inceptionDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: 'http://www.animalfriends.co.uk',
  vetFeeCapsUri: 'http://www.animalfriends.co.uk',
  pet: {
    petName: 'Platinum Dog',
    petBreed: 'St Bernard',
    petDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 10),
    petColour: 'White',
    petGender: 'Female',
    heightHands: null,
    heightInches: null,
    riderDOB: null,
    riderFirstName: null,
    riderSurname: null,
    type: 'dog',
    horseActivity: null,
    horsePostcode: null,
    microchipNo: '1234567890',
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  renewalDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
  coPayment: 0.35,
  excess: 99,
  monthlyTotal: 9.4,
  annualTotal: 112.8,
  outstandingPayment: 0,
  paymentStatus: PolicyPaymentStatus.RenewalDue,
  paymentFrequency: PolicyPaymentFrequency.Annually,
  preferredPaymentDay: 15,
  customerActionRequired: true,
  voluntaryExcess: 500,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.AnnualCustomerCanMakePayment,
  isLive: true,
  prizeDrawWinner: null,
  hasRenewalDocsGenerated: false,
};

export const MockPoliciesMap = {
  LiveCatOverduePolicy,
  LiveDogAnnualRenewalDuePolicy,
  LiveDogMonthlyRenewalDuePolicy,
  LiveCatAccidentOnlyUpToDatePolicy,
  LiveCatAccidentOnlyOverduePolicy,
  LiveHorsePolicy,
  LiveRiderMonthlyPolicy,
  LiveYoungRiderMonthlyPolicy,
  LiveCatE2EPolicy,
  LiveCatE2EV3Policy,
  E2EYoungRiderMonthlyPolicy,
  E2EAdultRiderMonthlyPolicy,
  LiveDogLifetimeAssurePolicy,
  LiveDogLifetimeAssurePlusPolicy,
  LiveDogTimeLimitedPolicy,
  LiveDogSilverPolicy,
  LiveDogSilverV3Policy,
  LiveDogGoldPolicy,
  LiveDogPlatinumPolicy,
  LiveDog10KPolicy,
  LiveDog18KPolicy,
  AnnualNoOutstandingPaymentLiveDog18KPolicy,
};

export const MockCatDogPoliciesMap = {
  LiveCatOverduePolicy,
  LiveDogAnnualRenewalDuePolicy,
  LiveDogMonthlyRenewalDuePolicy,
  LiveCatAccidentOnlyUpToDatePolicy,
  LiveCatAccidentOnlyOverduePolicy,
  LiveCatE2EPolicy,
  LiveDogLifetimeAssurePolicy,
  LiveDogLifetimeAssurePlusPolicy,
  LiveDogTimeLimitedPolicy,
  LiveDogSilverPolicy,
  LiveDogGoldPolicy,
  LiveDogPlatinumPolicy,
  LiveDog10KPolicy,
  LiveDog18KPolicy,
};

export const MockMonthlyPoliciesMap ={
  LiveCatAccidentOnlyUpToDatePolicy,
};

export const MockPolicies: Policy[] = Object.keys(MockPoliciesMap).map(
  (e) => MockPoliciesMap[e],
);

export const MockCatDogPolicies: Policy[] = Object.keys(MockCatDogPoliciesMap).map(
  (e) => MockPoliciesMap[e],
);

export const MockMonthlyPolicies: Policy[] = Object.keys(MockMonthlyPoliciesMap).map(
  (e) => MockMonthlyPoliciesMap[e],
);

export const MockPolicy = LiveDogAnnualRenewalDuePolicy;
