import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ClaimDetailEffects } from './effects/claim-detail.effects';
import { rootReducer } from './reducers/claim.reducer';
import { claimStateName } from './claim-state';

@NgModule({
  imports: [
    EffectsModule.forFeature([
      ClaimDetailEffects,
    ]),
    StoreModule.forFeature(claimStateName, rootReducer),
  ],
})
export class ClaimsStoreModule {}
