import { ApplicationRef, DoBootstrap, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UiModule } from '@shared/ui/ui.module';
import { CoreModule } from '@core/core.module';
import { ROOT_MODULES } from './root-modules';
import { HubRouterModule } from '@router/hub-router.module';
import { fakeBackendProvider } from '@testing/interceptors/fake-backend.interceptor';
import { CoreComponent } from '@core/core.component';
import { PolicyHolderModule } from '@features/account-details/store/policy-holder-store.module';
import { AnalyticsService } from '@core/analytics/services/analytics.service';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { ChangeCoverStoreModule } from '@features/change-cover/store/store.module';
import { PolicyStoreModule } from '@features/policies/store/policy-store.module';
import { NgIdleModule } from '@ng-idle/core';
import { localStorageSyncReducer } from './local-storage-sync.config';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  CommunicationPreferencesStoreModule,
} from '@features/communication-preferences/store/communication-preferences-store.module';
import { SymptomCheckerStoreModule } from '@features/joii/store/symptom-checker-store.module';
import { ClaimsStoreModule } from '@features/claims/store/store.module';

const providers = [];

if (environment.fakeBackend) {
  providers.push(fakeBackendProvider);
}

@NgModule({
  imports: [
    ...ROOT_MODULES,
    BrowserModule,
    HubRouterModule,
    CoreModule,
    UiModule,
    PolicyHolderModule,
    ChangeCoverStoreModule,
    PolicyStoreModule,
    ClaimsStoreModule,
    CommunicationPreferencesStoreModule,
    StoreModule.forRoot({}, { metaReducers: [localStorageSyncReducer] }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    BrowserAnimationsModule,
    NgIdleModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    SymptomCheckerStoreModule,
  ],
  providers: [
    {
      provide: 'googleTagManagerId',
      useFactory: (analyticsService: AnalyticsService) =>
        analyticsService.getGtmContainer(),
      deps: [AnalyticsService],
    },
    {
      provide: 'googleTagManagerAuth',
      useFactory: (analyticsService: AnalyticsService) =>
        analyticsService.getGtmAuth(),
      deps: [AnalyticsService],
    },
    {
      provide: 'googleTagManagerPreview',
      useFactory: (analyticsService: AnalyticsService) =>
        analyticsService.getGtmPreview(),
      deps: [AnalyticsService],
    },
    DatePipe,
    ...providers,
  ],
})
export class AppModule implements DoBootstrap {
  ngDoBootstrap(ref: ApplicationRef) {
    ref.bootstrap(CoreComponent);
  }
}
